import { h } from 'preact';
import { Product } from '@components/product/Product';
import styles from './Aluminium.less';

const IMAGES_PATH = '/assets/media/aluminium';

const IMAGES = {
  BALCONIES: [
    {
      name: 'production-windows',
      src: `${IMAGES_PATH}/production-windows.jpg`,
      alt: 'Алюминиевые раздвижные балконные рамы системы «Provedal»',
    },
  ],
  VERANDS: [
    {
      name: 'production-verands',
      src: `${IMAGES_PATH}/production-verands.png`,
      alt: 'Остекление веранд и террас при помощи двух систем: «Слайдинг-60» и «Слайдинг-90»',
    },
  ],
  MOSQUITO_NETS: [
    {
      name: 'production-mosquito-nets',
      src: `${IMAGES_PATH}/production-mosquito-nets.png`,
      alt: 'Раздвижные москитные сетки на алюминиевую раму',
    },
  ],
  MOSQUITO_PLISSE: [
    {
      name: 'production-mosquito-plisse',
      src: `${IMAGES_PATH}/production-mosquito-plisse.jpg`,
      alt: 'Москитные сетки-плиссе на окна, двери, балконы и беседки',
    },
  ],
};

export const Aluminium = () => (
  <div className={styles.container}>
    <div className={styles.title}>
      <p>
        Предприятие «АЛЮМАКС» изготавливает алюминиевые раздвижные конструкции по заказам
        строительно-монтажных организаций и индивидуальных предпринимателей.
      </p>
      <p>
        <strong>Мы не производим монтаж алюминиевых конструкций!</strong>
      </p>
    </div>
    <Product
      title={<span>Алюминиевые раздвижные балконные рамы</span>}
      images={IMAGES.BALCONIES}
      description={
        <div>
          <p>
            Система "Provedal" - лучшая, на наш взгляд, раздвижная конструкция из
            представленных на белорусском рынке. Конструкция состоит из коробки,
            выполненной заодно с направляющими, и створок на роликах. Профиль достаточно
            узкий, что значительно увеличивает световой проем. Ролики на подшипниках
            обеспечат плавный ход створок на весь срок службы. Балконные рамы остекляются
            прозрачным стеклом 4 мм. Стекло в раме установлено в U-образном резиновом
            уплотнителе. Количество створок определяется размерами объекта и пожеланиями
            заказчика. В одной раме возможно выполнение от 2-х до 7-ми створок.
          </p>
          <p>
            Профиль поставляется с завода в двух цветовых исполнениях: белом (RAL 9016) и
            коричневом (RAL 8017).
          </p>
          <p>
            Мы можем дополнительно укомплектовать раму верхним и нижним отливом,
            подоконником, противомоскитной сеткой.
          </p>
        </div>
      }
    />
    <Product
      title={<span>Остекление веранд, террас и беседок</span>}
      images={IMAGES.VERANDS}
      description={
        <div>
          <p>
            На сегодняшний день остекление веранд и террас с применением алюминиевых
            конструкций пользуется огромной популярностью. Такое остекление обеспечивает
            надёжную защиту от ветра и осадков, посторонних шумов и пыли и гарантирует
            комфортное использование пространства в любое время года.
          </p>
          <p>
            Мы предлагаем "холодное" остекление веранд и террас при помощи двух похожих
            систем: Слайдинг-60 и Слайдинг-90. Цифры обозначают ширину коробки в мм.
          </p>
          <p>
            Слайдинг-60 - это аналог раздвижной системы "Provedal", которая применяется
            для холодного остекления балконов и лоджий. Створки и коробка изготавливаются
            из такого же профиля. Но из-за большей высоты створок, мы рекомендуем в каждой
            створке делать горизонтальный импост, который придаст створке дополнительную
            жёсткость и безопасность при случайном повреждении стекла. Количество створок
            в коробке может быть от 2-х до 7-ми. Максимальное открывание в системе
            Слайдинг-60 - 50%!
          </p>
          <p>
            Слайдинг-90 создана на основе системы Слайдинг-60. Створки изготавливаются из
            такого же профиля, что и в системе Слайдинг-60. Основное отличие - коробка в
            Слайдинг-90 имеет три дорожки, по которым катаются створки. Поэтому и ширина
            этой коробки не 60, а 90 мм. Количество створок в коробке системы Слайдинг-90
            может быть либо 3, либо 6. Максимальное открывание - 66%!
          </p>
        </div>
      }
    />
    <Product
      title={<span>Раздвижные москитные сетки на алюминиевую раму</span>}
      images={IMAGES.MOSQUITO_NETS}
      description={
        <div>
          <p>
            Сдвижные москитные сетки работают по принципу шкафа-купе: они передвигаются на роликах
            по специально установленным направляющим. Эти направляющие крепятся с помощью саморезов
            с внутренней стороны внизу и вверху вашей балконной рамы. Лучшим решением будет
            установка направляющих по всей длине балконной рамы, что позволит легко перемещать
            москитную сетку от одной створки к другой.
          </p>
          <p>
            Важно учитывать, что если на потолке балкона или лоджии уже есть отделка в виде
            натяжного полотна, гипсокартона или панелей, установка таких москитных сеток становится
            невозможной. В этом случае рекомендуется произвести монтаж сетки до отделки потолка.
          </p>
          <p>
            Цветовые варианты направляющих и каркаса стандартно представлены в белом и коричневом
            цветах. Однако возможно индивидуальное окрашивание в любой цвет из каталога RAL, что
            позволит гармонично вписать москитную сетку в дизайн вашего балкона или лоджии.
          </p>
        </div>
      }
    />
    <Product
      title={<span>Москитные сетки-плиссе на окна, двери, балконы и беседки</span>}
      images={IMAGES.MOSQUITO_PLISSE}
      description={
        <div>
          <p>
            Москитные сетки-плиссе — это инновационное решение для защиты вашего дома от насекомых
            и пыли. Они представляют собой полотно, складывающееся гармошкой, что обеспечивает
            компактность и удобство использования. Рама сетки изготовлена из легкого и прочного
            алюминия, а гофрированное полотно удерживается несколькими рядами натянутых шнуров.
          </p>
          <p>
            Преимущества москитных сеток-плиссе
          </p>
          <ul>
            <li>Оригинальный и современный дизайн, который украсит любой интерьер.</li>
            <li>Экономия пространства по сравнению с распашными конструкциями в дверных проемах.
            </li>
            <li>Легкое и плавное управление с возможностью фиксации сетки в нужном положении.</li>
            <li>Эффективное проветривание помещения без препятствий для свежего воздуха.</li>
            <li>Отличная видимость внешнего пространства и происходящего снаружи.</li>
            <li>Прочная и надежная конструкция с длительным сроком службы.</li>
          </ul>
          <p>
            Используя москитные сетки-плиссе, вы легко и быстро превратите открытую беседку или
            террасу в уютное место для круглосуточного летнего отдыха, свободного от назойливых
            комаров, мух и ночных мотыльков. Такие сетки позволяют закрыть большие проемы, что
            особенно актуально для загородных домов.
          </p>
          <p>
            Когда сетка не используется, плиссированный экран можно полностью сложить. Это
            сохраняет сетку чистой, невидимой и защищенной от возможных повреждений. Уход за
            сеткой предельно прост: достаточно воспользоваться щеточкой или пылесосом для
            удаления загрязнений.
          </p>
          <p>
            Цвет направляющих и каркаса стандартно представлен в белом и коричневом оттенках.
            Однако по индивидуальному заказу возможна окраска в любой цвет из каталога RAL,
            что позволит гармонично вписать москитную сетку в дизайн вашего помещения.
          </p>
          <p>
            Москитные сетки-плиссе на окна, двери, балконы и беседки — это сочетание
            функциональности, эстетики и комфорта. Защитите свой дом от насекомых и наслаждайтесь
            свежим воздухом без ограничений!
          </p>
        </div>
      }
    />
  </div>
);
